<div class="content">
  <div class="container">
    <div class="progressbar-container">
      <div
        class="progressbar"
        [ngStyle]="{
          transform: 'translateX(' + progress + '%)',
          transition: '0.2s ease'
        }"
      ></div>
    </div>
  </div>
</div>
