<div class="content">
  <h1 *ngIf="!exerciseMode">My Vocabulary</h1>
  <h1 *ngIf="exerciseMode">Choose Group To Practice</h1>
  <div class="container">
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let group of groups; let i = index"
        class="mat-elevation-z6"
        [expanded]="preventCollapse(group.groupNum)"
        (focusin)="newGroupMode = false"
      >
        <mat-expansion-panel-header
          *ngIf="!exerciseMode || getGroupWords(group.groupNum).length > 0"
        >
          <div class="flex-title" (click)="changePanReset()">
            <span *ngIf="!hideOnGroupEdit(group.groupNum)">
              {{ group.name | titlecase }}
              <i
                class="far fa-edit"
                *ngIf="showOnEdit(group.groupNum)"
                (click)="onEditGroupName()"
                appStopPropagation
              ></i>
            </span>
            <app-group-form
              *ngIf="showOnEdit(group.groupNum) && groupEdit"
              [group]="group"
              (onFinishSubmit)="groupEdit = false"
            ></app-group-form>
            <div class="bars-wrap">
              <app-rating-ui
                *ngIf="getGroupWords(group.groupNum).length > 0"
                uiType="bars"
                [score]="group.averageProficiency"
              ></app-rating-ui>
            </div>
          </div>
        </mat-expansion-panel-header>
        <div class="grid-conteiner">
          <div
            class="wordCardDiv"
            *ngFor="let word of getGroupWords(group.groupNum)"
          >
            <mat-card class="mat-elevation-z4">
              <span *ngIf="hideOnEditWord(word.id, group.groupNum)">
                {{ word.name | titlecase }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path d="M124.5 199.5H302" stroke-width="6" />
                  <path d="M149.5 189.5h125m-125 20h125" stroke-width="3" />
                  <path d="M3.75 46.5H96.5" stroke-width="6" />
                  <path
                    d="M16.8134 40.5h65.3169m-65.3169 12h65.3169"
                    stroke-width="3"
                  />
                  <path d="M1.25 9.5h16.5" stroke-width="1.4" />
                  <path d="M5.75 6.75h7.5m-7.5 5.5h7.5" />
                </svg>
                {{ word.translation | titlecase }}
              </span>
              <app-word-form
                *ngIf="showOnEditWord(word.id, group.groupNum)"
                (onFinishSubmit)="wordEdit = false"
                [word]="word"
              ></app-word-form>
              <div class="spacer"></div>
              <div class="ui-wrap">
                <i
                  *ngIf="
                    !translationArrOpen.includes(word.id) &&
                    hideOnEdit(group.groupNum) &&
                    word.additionalTr.length > 0
                  "
                  class="far fa-eye"
                  (click)="toggleTranslations(word.id)"
                ></i>
                <i
                  *ngIf="
                    translationArrOpen.includes(word.id) &&
                    hideOnEdit(group.groupNum) &&
                    word.additionalTr.length > 0
                  "
                  class="far fa-eye-slash"
                  (click)="toggleTranslations(word.id)"
                ></i>
                <i
                  class="far fa-question-circle"
                  *ngIf="hideOnEdit(group.groupNum) && word.tips"
                ></i>

                <i
                  class="far fa-edit"
                  *ngIf="
                    showOnEdit(group.groupNum) &&
                    hideOnEditWord(word.id, group.groupNum)
                  "
                  (click)="onEditWord(word.id)"
                ></i
                ><i
                  class="far fa-trash-alt"
                  *ngIf="
                    showOnEdit(group.groupNum) &&
                    hideOnEditWord(word.id, group.groupNum)
                  "
                  (click)="onDeleteWord(word.id)"
                ></i>
                <app-rating-ui
                  uiType="stars"
                  [score]="word.proficiency"
                  *ngIf="hideOnEdit(group.groupNum)"
                ></app-rating-ui>
                <div class="tooltip">
                  <span>{{ word.tips | titlecase }} </span>
                </div>
              </div>
            </mat-card>
            <div
              class="extraTranslations"
              *ngIf="translationArrOpen.includes(word.id)"
              @expandDown
            >
              <app-extra-tr-card [word]="word"></app-extra-tr-card>
            </div>
          </div>
          <div
            class="mat-elevation-z4 add-word-div"
            *ngIf="showOnEdit(group.groupNum) && newWordMode === false"
            (click)="onAddNewWord()"
          >
            <button mat-button class="btn btn-new-word">
              Add New Word <mat-icon>add_box</mat-icon>
            </button>
          </div>
          <div
            class="wordCardDiv"
            *ngIf="showOnEdit(group.groupNum) && newWordMode === true"
          >
            <mat-card>
              <app-word-form
                [group]="group"
                (onFinishSubmit)="newWordMode = false"
              ></app-word-form>
            </mat-card>
          </div>
        </div>
        <mat-action-row>
          <button
            *ngIf="conditionalForSeeAll(group.groupNum) && editMode === false"
            mat-button
            color="warn"
            class="dlt-btn"
            (click)="openAllTranslations(group.groupNum)"
          >
            See All
          </button>
          <button
            *ngIf="
              !conditionalForSeeAll(group.groupNum) &&
              editMode === false &&
              areThereAddTr(group.groupNum)
            "
            mat-button
            color="warn"
            class="dlt-btn"
            (click)="closeAllTranslations()"
          >
            Collapse All
          </button>
          <button
            *ngIf="!exerciseMode"
            mat-button
            class="edit-btn"
            (click)="onEditMode(group.groupNum)"
          >
            {{
              editMode === true && groupEditId === group.groupNum
                ? "Save"
                : "Edit"
            }}
          </button>
          <button
            *ngIf="!exerciseMode"
            mat-button
            color="warn"
            class="dlt-btn"
            (click)="onDeleteGroup(group.name, group.groupNum)"
          >
            DELETE
          </button>
          <button
            *ngIf="exerciseMode && exerciseType === 'spelling'"
            mat-button
            class="practice-btn"
            [routerLink]="['/exercises/spelling', 'word-group', group.groupNum]"
            routerLinkActive="mat-accent"
          >
            Practice
          </button>
          <button
            *ngIf="exerciseMode && exerciseType === 'quiz'"
            mat-button
            class="practice-btn"
            [routerLink]="['/exercises/quiz', 'word-group', group.groupNum]"
            routerLinkActive="mat-accent"
          >
            Practice
          </button>
        </mat-action-row>
      </mat-expansion-panel>
      <div
        class="add-group-div"
        *ngIf="!exerciseMode"
        [ngStyle]="{
          transition:
            'background-color ' + (groups.length / 8 + 0.2) + 's ease-in-out'
        }"
      >
        <button
          *ngIf="newGroupMode === false"
          class="btn btn-add-group"
          mat-button
          (click)="newGroupMode = true"
          appStopPropagation
        >
          Create New Group <mat-icon>add_box</mat-icon>
        </button>
        <app-group-form
          class="input-add-group"
          *ngIf="newGroupMode === true"
          (onFinishSubmit)="newGroupMode = false"
        ></app-group-form>
      </div>
    </mat-accordion>
  </div>
</div>
