<div class="content" *ngIf="resultsArr.length > 0">
  <h1>Results:</h1>
  <h2>answered correctly {{ countCorrect }} out of {{ countTotal }}</h2>
  <div class="container">
    <mat-card
      *ngFor="let word of currentWordSet; let i = index"
      class="mat-elevation-z4"
      [ngStyle]="{
        'background-color':
          resultsArr[i] === true
            ? 'var(--answer-correct)'
            : 'var(--answer-wrong)'
      }"
    >
      <span>
        <b>{{ word.name | titlecase }} </b>
        {{ word.translation | titlecase }}
      </span>
    </mat-card>
  </div>
</div>
