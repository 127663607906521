<main [ngClass]="returnThemeClass()" class="globalVars">
  <!-- <img class="img-container" src=".\assets\imgs\Untitled.png" /> -->
  <div class="streetlight">
    <img
      class="img-container"
      draggable="false"
      src=".\assets\imgs\streetlight.webp"
      alt="streetlight"
    />
    <div class="light-blob"></div>
  </div>

  <app-navbar></app-navbar>
  <router-outlet></router-outlet>
  <div class="blobs-box" *ngIf="pixie" @fadeIn>
    <div class="blob"></div>
    <div class="blob"></div>
    <div class="blob"></div>
    <div class="blob" *ngIf="extraPixies"></div>
    <div class="blob" *ngIf="extraPixies"></div>

    <div class="blob" *ngIf="extraPixies"></div>
    <div class="blob" *ngIf="extraPixies"></div>
    <div class="blob" *ngIf="extraPixies"></div>
    <div class="blob" *ngIf="extraPixies"></div>
    <div class="blob" *ngIf="extraPixies"></div>
    <div class="blob" *ngIf="extraPixies"></div>
    <div class="blob" *ngIf="extraPixies"></div>
    <div class="blob" *ngIf="extraPixies"></div>
    <div class="blob" *ngIf="extraPixies"></div>
    <div class="blob" *ngIf="extraPixies"></div>
  </div>
</main>
