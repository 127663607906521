<nav>
  <ul class="desktop-menu">
    <li>
      <a mat-button routerLink="/"
        ><mat-icon> home </mat-icon><span class="nav-text"> Home</span>
      </a>
    </li>
    <li class="pseudo-el"></li>
    <li class="spacer"></li>
    <li *ngIf="this.authService.getIsAuth()">
      <a mat-button routerLink="/my-vocabulary" routerLinkActive="activeLink"
        ><mat-icon> auto_stories </mat-icon
        ><span class="nav-text"> Glossary</span></a
      >
    </li>

    <li *ngIf="this.authService.getIsAuth()">
      <a mat-button routerLink="/exercises" routerLinkActive="activeLink">
        <mat-icon> fitness_center </mat-icon
        ><span class="nav-text"> Training</span>
      </a>
    </li>
    <li class="spacer"></li>
    <li *ngIf="!this.authService.getIsAuth()">
      <a mat-button routerLink="/auth/login" routerLinkActive="activeLink"
        ><mat-icon> login </mat-icon><span class="nav-text"> Log In</span>
      </a>
    </li>
    <li *ngIf="!this.authService.getIsAuth()">
      <a mat-button routerLink="/auth/signin" routerLinkActive="activeLink"
        ><mat-icon> person_add </mat-icon> Sign In
      </a>
    </li>
    <li (click)="onSettings()" (mouseleave)="startTimer()">
      <a mat-button
        ><mat-icon>settings</mat-icon> <span class="nav-text"> Settings</span>
      </a>
      <app-settings-popup
        *ngIf="dropdownOpen"
        (mouseleave)="startTimer()"
        (mouseenter)="stopTimer()"
        @popup
      ></app-settings-popup>
    </li>
    <li *ngIf="this.authService.getIsAuth()">
      <p *ngIf="authService.whoAmI" class="loggedInInfo">
        {{ authService.whoAmI }}
      </p>
      <a
        mat-button
        (click)="this.authService.logout()"
        routerLinkActive="activeLink"
        ><mat-icon>logout</mat-icon> <span class="nav-text"> Log Out</span>
      </a>
    </li>
  </ul>

  <!-- MOBILE MENU -->
  <div class="mobile-menu">
    <a mat-button routerLink="/" class="page-logo-home"
      ><mat-icon> home </mat-icon><span class="nav-text"> Home</span>
    </a>
    <a
      mat-button
      class="open-btn"
      (click)="onOpenMenu()"
      routerLinkActive="activeLink"
    >
      <mat-icon class="menu-icon">menu</mat-icon>
    </a>
    <div class="dropdown-menu" [ngClass]="menuOpen ? 'droped' : ''">
      <ul>
        <li *ngIf="this.authService.getIsAuth()">
          <a
            routerLink="/my-vocabulary"
            routerLinkActive="activeLink"
            (click)="menuOpen = false; onFocusoutSettings()"
          >
            <mat-icon class="arrow"> expand_more </mat-icon>
            <mat-icon> auto_stories </mat-icon>
            <mat-icon class="arrow"> expand_more </mat-icon>
          </a>
        </li>

        <li *ngIf="this.authService.getIsAuth()">
          <a
            routerLink="/exercises"
            routerLinkActive="activeLink"
            (click)="menuOpen = false; onFocusoutSettings()"
          >
            <mat-icon class="arrow"> expand_more </mat-icon>
            <mat-icon> fitness_center </mat-icon>
            <mat-icon class="arrow"> expand_more </mat-icon>
          </a>
        </li>
        <li *ngIf="!this.authService.getIsAuth()">
          <a
            routerLink="/auth/login"
            routerLinkActive="activeLink"
            (click)="menuOpen = false; onFocusoutSettings()"
          >
            <mat-icon class="arrow"> expand_more </mat-icon>
            <mat-icon> login </mat-icon>
            <mat-icon class="arrow"> expand_more </mat-icon>
          </a>
        </li>

        <li *ngIf="this.authService.getIsAuth()">
          <a
            routerLinkActive="activeLink"
            (click)="
              this.authService.logout(); menuOpen = false; onFocusoutSettings()
            "
          >
            <mat-icon class="arrow"> expand_more </mat-icon>
            <mat-icon> logout </mat-icon>
            <mat-icon class="arrow"> expand_more </mat-icon>
          </a>
        </li>

        <li>
          <a routerLinkActive="activeLink" (click)="onSettingsMobile()">
            <mat-icon class="arrow"> expand_more </mat-icon>
            <mat-icon> settings </mat-icon>
            <mat-icon class="arrow"> expand_more </mat-icon>
          </a>
        </li>
        <li @mob-settings *ngIf="dropdownMobileOpen" class="settings-popup">
          <app-settings-popup></app-settings-popup>
        </li>
        <li>
          <a
            routerLinkActive="activeLink"
            (click)="menuOpen = false; this.dropdownOpen = false"
          >
            <mat-icon class="arrow"> expand_more </mat-icon>
            <mat-icon> cancel </mat-icon>
            <mat-icon class="arrow"> expand_more </mat-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- <li *ngIf="!this.authService.getIsAuth()">
          <a
            routerLink="/auth/signin"
            routerLinkActive="activeLink"
            (click)="menuOpen = false; onFocusoutSettings()"
          >
            <mat-icon class="arrow"> expand_more </mat-icon>
            <mat-icon> person_add </mat-icon>
            <mat-icon class="arrow"> expand_more </mat-icon>
          </a>
        </li> -->
