<div class="container">
  <mat-card>
    <form (submit)="onLogin(loginForm)" #loginForm="ngForm">
      <mat-form-field>
        <input
          matInput
          name="email"
          ngModel
          type="email"
          placeholder="E-Mail"
          #emailInput="ngModel"
          required
          email
        />
        <mat-error *ngIf="emailInput.invalid"
          >Please enter a valid email.</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <input
          type="password"
          name="password"
          ngModel
          matInput
          placeholder="Password"
          #passwordInput="ngModel"
          required
        />
        <mat-error *ngIf="passwordInput.invalid"
          >Please enter a valid password.</mat-error
        >
      </mat-form-field>
      <mat-error
        *ngIf="!emailInput.invalid && !passwordInput.invalid && logInFailed"
        @fadeIn
        >Invalid credentials</mat-error
      >
      <button mat-raised-button color="accent" type="submit" class="btn">
        Login
      </button>
    </form>
  </mat-card>
</div>
