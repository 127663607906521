<div class="content">
  <div class="container">
    <form [formGroup]="wordForm" (submit)="onSubmit()">
      <div class="grid-container">
        <mat-form-field class="word-field">
          <input
            class="wordInput"
            formControlName="word"
            matInput
            type="search"
            placeholder="Word"
            autocomplete="off"
            data-lpignore="true"
            maxlength="21"
          />
        </mat-form-field>
        <mat-form-field class="translation-field">
          <input
            class="translationInput"
            formControlName="translation"
            matInput
            type="search"
            placeholder="Translation"
            autocomplete="off"
            data-lpignore="true"
            maxlength="21"
          />
        </mat-form-field>
        <mat-form-field class="tips-field">
          <input
            class="tipsInput"
            formControlName="tips"
            matInput
            type="search"
            placeholder="Tips"
            autocomplete="off"
            data-lpignore="true"
            maxlength="40"
          />
        </mat-form-field>
        <mat-form-field class="additionalTr-field" appearance="fill">
          <mat-label
            >Additional translations (use comma as separator)</mat-label
          >
          <textarea
            formControlName="additionalTr"
            style="overflow: hidden"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            maxlength="200"
          ></textarea>
        </mat-form-field>
        <div class="btn-div">
          <button class="btn-submit" type="submit" [disabled]="!wordForm.valid">
            <i class="fas fa-check-square"></i>
          </button>
          <button class="btn btn-close" (click)="onClose()">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
