<div class="grid-conteiner">
  <app-progress-bar></app-progress-bar>
  <mat-card class="mat-elevation-z24">
    <mat-card-title *ngIf="words && translateDirection">{{
      this.activeWord.name | titlecase
    }}</mat-card-title>
    <mat-card-title *ngIf="words && !translateDirection">{{
      this.activeWord.translation | titlecase
    }}</mat-card-title>
    <mat-card-subtitle *ngIf="words"
      ><i class="far fa-question-circle" *ngIf="this.activeWord.tips"></i>
      <div class="tooltip">
        {{ this.activeWord.tips | titlecase }}
      </div></mat-card-subtitle
    >
    <mat-card-content>
      <mat-form-field>
        <input
          maxlength="23"
          type="text"
          matInput
          class="res-input"
          autocomplete="off"
          [disabled]="!pendingFstSub"
          [(ngModel)]="inputValue"
          #wordInput
          [style.color]="
            !pendingFstSub && correct
              ? 'var(--answer-correct)'
              : !pendingFstSub && !correct
              ? 'var(--answer-wrong)'
              : 'var(--text-primary)'
          "
          (keyup.enter)="onSend()"
        />
        <input
          type="text"
          class="grayCardinal"
          #grayCardinal
          (keyup.enter)="onSend()"
        />
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions
      ><button class="sbm-btn" mat-button color="primary" (click)="onSend()">
        {{
          pendingFstSub
            ? "Submit"
            : !pendingFstSub && activeWordIndex === words.length - 1
            ? "Finish"
            : !pendingFstSub
            ? "Next"
            : ""
        }}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
