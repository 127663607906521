<div class="grid-conteiner">
  <app-progress-bar></app-progress-bar>
  <mat-card class="mat-elevation-z24">
    <mat-card-title *ngIf="words && translateDirection">{{
      this.activeWord.name | titlecase
    }}</mat-card-title>
    <mat-card-title *ngIf="words && !translateDirection">{{
      this.activeWord.translation | titlecase
    }}</mat-card-title>
    <mat-card-subtitle *ngIf="words"
      ><i class="far fa-question-circle" *ngIf="this.activeWord.tips"></i>
      <div class="tooltip">
        {{ this.activeWord.tips | titlecase }}
      </div></mat-card-subtitle
    >
    <mat-card-content *ngIf="fourAnswers">
      <div
        class="option opt-1"
        [ngClass]="{
          correct: correctResponse == fourAnswers[0] && confirmedResponse,
          wrong:
            correctResponse !== fourAnswers[0] &&
            confirmedResponse &&
            confirmedResponse === fourAnswers[0],
          selected: selectedOption == 1 && !confirmedResponse
        }"
        (click)="onOptionSelect(fourAnswers[0], 1)"
      >
        <span>{{ fourAnswers[0] | titlecase }}</span>
      </div>
      <div
        *ngIf="fourAnswers[1]"
        class="option opt-2"
        [ngClass]="{
          correct: correctResponse == fourAnswers[1] && confirmedResponse,
          wrong:
            correctResponse !== fourAnswers[1] &&
            confirmedResponse &&
            confirmedResponse === fourAnswers[1],
          selected: selectedOption == 2 && !confirmedResponse
        }"
        (click)="onOptionSelect(fourAnswers[1], 2)"
      >
        <span>{{ fourAnswers[1] | titlecase }}</span>
      </div>
      <div
        *ngIf="fourAnswers[2]"
        class="option opt-3"
        [ngClass]="{
          correct: correctResponse == fourAnswers[2] && confirmedResponse,
          wrong:
            correctResponse !== fourAnswers[2] &&
            confirmedResponse &&
            confirmedResponse === fourAnswers[2],
          selected: selectedOption == 3 && !confirmedResponse
        }"
        (click)="onOptionSelect(fourAnswers[2], 3)"
      >
        <span>{{ fourAnswers[2] | titlecase }}</span>
      </div>
      <div
        *ngIf="fourAnswers[3]"
        class="option opt-4"
        [ngClass]="{
          correct: correctResponse == fourAnswers[3] && confirmedResponse,
          wrong:
            correctResponse !== fourAnswers[3] &&
            confirmedResponse &&
            confirmedResponse === fourAnswers[3],
          selected: selectedOption == 4 && !confirmedResponse
        }"
        (click)="onOptionSelect(fourAnswers[3], 4)"
      >
        <span>{{ fourAnswers[3] | titlecase }}</span>
      </div>
    </mat-card-content>
    <mat-card-actions
      ><button class="sbm-btn" mat-button color="primary" (click)="onSubmit()">
        {{
          pendingFstSub
            ? "Submit"
            : !pendingFstSub && activeWordIndex === words.length - 1
            ? "Finish"
            : !pendingFstSub
            ? "Next"
            : ""
        }}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
